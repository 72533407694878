import Vue from 'vue'
import App from './App.vue'

// ------------------------------------------------
// Pinia
// ------------------------------------------------
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

// ------------------------------------------------
// Pinia Persisted Store
// ------------------------------------------------
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
pinia.use(piniaPluginPersistedstate)

// ------------------------------------------------
// Router
// ------------------------------------------------
import router from './router/index.js'

// it is crucial to do Vue.use(VueRouter)
// after the router has been exported
// otherwise not Pinia store found
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// ------------------------------------------------
// Broadcast
// ------------------------------------------------
import { useBroadcastChannel } from '@vueuse/core'
Vue.use(useBroadcastChannel)

// ------------------------------------------------
// Portal Vue
// ------------------------------------------------
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// ------------------------------------------------
// Vueform
// ------------------------------------------------
import Vueform from '@vueform/vueform'
import vueformConfig from '../vueform.config'

Vue.use(Vueform, vueformConfig)

// ------------------------------------------------
// Libs
// ------------------------------------------------
import axios from '@/libs/axios'
import '../tailwind-vue.config.js'

// ------------------------------------------------
// SCSS
// ------------------------------------------------
import '../tailwind.scss'

// ------------------------------------------------
// Validation
// ------------------------------------------------
import { localize } from 'vee-validate'
import en from '@/libs/vee-validate/en.json'
localize({ en })

import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import { required, required_if, email } from 'vee-validate/dist/rules'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', required)
extend('required_if', required_if)
extend('email', email)
extend('positive', (value) => value >= 0)
extend('arraymin', {
	validate(value, args) {
		if (value.length >= args.length) return true
		else return `Add at least ${args.length}`
	},
	params: ['length'],
})

setInteractionMode('passive')

// ------------------------------------------------
// Filters
// ------------------------------------------------
import {
	capitalize,
	date,
	time,
	dateTime,
	dateTimeSent,
	longDateTime,
	utcLocalDateTime,
	quarter,
	year,
	trunc,
	upperFirst,
	lowerFirst,
} from '@/utils/filters'

Vue.filter('capitalize', capitalize)
Vue.filter('date', date)
Vue.filter('time', time)
Vue.filter('dateTime', dateTime)
Vue.filter('dateTimeSent', dateTimeSent)
Vue.filter('longDateTime', longDateTime)
Vue.filter('quarter', quarter)
Vue.filter('year', year)
Vue.filter('trunc', trunc)
Vue.filter('upperFirst', upperFirst)
Vue.filter('lowerFirst', lowerFirst)
Vue.filter('utcLocalDateTime', utcLocalDateTime)

// usage: {{ file.size | prettyBytes }}
Vue.filter('prettyBytes', function (num) {
	if (typeof num !== 'number' || isNaN(num)) {
		throw new TypeError('Expected a number')
	}

	var exponent
	var unit
	var neg = num < 0
	var units = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	if (neg) {
		num = -num
	}

	if (num < 1) {
		return (neg ? '-' : '') + num + ' B'
	}

	exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
	num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
	unit = units[exponent]

	return (neg ? '-' : '') + num + ' ' + unit
})

// ------------------------------------------------
// BROADCASTING
// ------------------------------------------------
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
	broadcaster: 'pusher',
	key: process.env.VUE_APP_PUSHER_APP_KEY,
	cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
	forceTLS: true,
	authorizer: (channel) => {
		return {
			authorize: (socketId, callback) => {
				axios
					.post(process.env.VUE_APP_PUSHER_AUTH_HOST, {
						socket_id: socketId,
						channel_name: channel.name,
					})
					.then((response) => callback(false, response.data))
					.catch((error) => callback(true, error))
			},
		}
	},
})

// ------------------------------------------------
// TIPPY
// ------------------------------------------------
import VueTippy, { TippyComponent } from 'vue-tippy'
import 'tippy.js/themes/light.css'

Vue.use(VueTippy, {
	inertia: true,
	interactive: true,
	arrow: false,
	animateFill: false,
	placement: 'bottom',
	distance: 7,
	theme: 'light',
	animation: 'shift-away',
	trigger: 'click',
	maxWidth: '100%',
})
Vue.component('tippy', TippyComponent)

// ------------------------------------------------
// VUE TOASTIFICATION
// ------------------------------------------------
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

Vue.use(Toast, {
	position: 'bottom-right',
	timeout: 2000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: 'button',
	icon: true,
	rtl: false,
	transition: 'Vue-Toastification__fade',
	maxToasts: 20,
	newestOnTop: true,
})

// ------------------------------------------------
// COUNTRY FLAGS
// ------------------------------------------------
// https://github.com/ubaldop/vue-country-flag
import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

// ------------------------------------------------
// GLOBAL COMPONENTS
// ------------------------------------------------
import Icon from '@/components/Icon'
Vue.component('icon', Icon)

import Arrow from '@/components/Arrow'
Vue.component('arrow', Arrow)

import Spinner from '@/components/Spinner'
Vue.component('spinner', Spinner)

import RysqerList from '@/components/List/List'
Vue.component('rysqer-list', RysqerList)

import RysqerListItem from '@/components/List/Item'
Vue.component('rysqer-list-item', RysqerListItem)

import RysqerListSearch from '@/components/List/Search'
Vue.component('rysqer-list-search', RysqerListSearch)

import RysqerCollapsible from '@/components/Collapsible'
Vue.component('rysqer-collapsible', RysqerCollapsible)

import RysqerMore from '@/components/More'
Vue.component('rysqer-more', RysqerMore)

import RysqerForm from '@/components/Form'
Vue.component('rysqer-form', RysqerForm)

import RysqerInfo from '@/components/Info'
Vue.component('rysqer-info', RysqerInfo)

import RysqerStats from '@/components/Stats'
Vue.component('rysqer-stats', RysqerStats)

import RysqerViews from '@/layout/Views'
Vue.component('rysqer-views', RysqerViews)

import RysqerStageTabs from '@/layout/Tabs'
Vue.component('rysqer-stage-tabs', RysqerStageTabs)

// ------------------------------------------------
// VUE
// ------------------------------------------------
Vue.config.productionTip = false

new Vue({
	pinia,
	router,
	render: (h) => h(App),
}).$mount('#app')
