<template>
	<div>
		<div class="d-collapse d-collapse-arrow rounded-none" :class="[containerClass]">
			<input :type="type" name="rysqer-collapsible" class="min-h-0" v-model="isExpanded" />

			<div class="d-collapse-title">
				<div class="flex justify-between items-center gap-3">
					<div class="flex items-center gap-3">
						<div class="rysqer-identifier" :class="symbolClass" v-if="symbol || icon">
							<span v-if="symbol">{{ symbol }}</span>
							<icon v-if="icon" :icon="icon" :cls="iconClass"></icon>
						</div>

						<div>
							<p :class="labelClass">{{ label }}</p>
							<p class="text-sm line-clamp-1" v-if="description && !isExpanded">
								{{ description }}
							</p>
						</div>
					</div>

					<div
						v-for="(badge, index) in badges.filter((b) => b.condition == null || b.condition == true)"
						:key="index"
						class="d-badge"
						:class="badge.class"
					>
						<div class="d-tooltip" :data-tip="badge.tooltip ?? null">
							<icon :icon="badge.icon" cls="w-4 h-4 text-inherit" v-if="badge.icon"></icon>
							<span v-else>{{ badge.name }}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="d-collapse-content">
				<template v-if="isExpanded">
					<slot></slot>
				</template>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
	expanded: {
		type: Boolean,
		default: false,
	},
	type: {
		type: String,
		default: 'checkbox',
		validator: function (value) {
			return ['checkbox', 'radio'].indexOf(value) !== -1
		},
	},
	label: {
		type: String,
	},
	labelClass: {
		type: String,
		default: 'text-lg font-bold',
	},
	description: {
		type: String,
	},
	icon: {
		type: String,
	},
	iconClass: {
		type: String,
	},
	symbol: {
		type: String,
	},
	symbolClass: {
		type: String,
	},
	badges: {
		type: [Array, Object],
		default: () => {
			return []
		},
	},
	containerClass: {
		type: String,
	},
})

const isExpanded = ref(props.expanded)
</script>
