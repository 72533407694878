var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[_vm.containerClass]},[_c('ol',{class:[_vm.listClass]},_vm._l((_vm.items.filter((i) => i.condition == null || i.condition)),function(item,index){return _c('li',{key:index,class:[_vm.itemClass]},[_c('div',{staticClass:"flex items-center gap-2"},[(item.icon)?_c('icon',{staticClass:"flex-none min-w-8",attrs:{"icon":item.icon}}):_vm._e(),_c('div',{staticClass:"flex-1",class:[
						item.class,
						
						{ 'flex justify-between items-center gap-2': _setup.canFlex(item) },
						{ 'space-y-2': !_setup.canFlex(item) },
						{ 'flex-wrap': _setup.canWrap(item) },
					]},[_c('div',{class:item.labelClass},[_c('p',{staticClass:"text-sm font-semibold"},[_vm._v(_vm._s(item.label))]),(item.description)?_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(item.description))]):_vm._e()]),_c('div',{class:[item.valueClass, { 'text-right': _setup.canWrap(item) }],on:{"click":function($event){item.link ? item.link(item) : null}}},[(item.link)?_c('a',{staticClass:"text-sm d-link d-link-primary"},[_vm._v(_vm._s(item.value))]):_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(item.value))])])])],1),(item.detail)?_c('div',{class:[_vm.itemClass]},[_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(item.detail))])]):_vm._e()])}),0),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }