import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/libs/axios'

export const useOptionsStore = defineStore('rysqer-options-store', () => {
	const hydrating = ref(false)

	const controls = ref([])
	const subscribedControls = ref([])

	const currentYear = ref()
	const years = ref([])

	const currentFiscalYear = ref()
	const fiscalYears = ref([])

	const currentQuarter = ref()
	const quarters = ref([])

	const currentFiscalQuarter = ref()
	const fiscalQuarters = ref([])

	const countries = ref([])
	const processes = ref([])
	const businessProcesses = ref([])

	const frequencies = ref([])

	const loadControls = async () => {
		return axios.get(`/options/controls`).then(({ data }) => {
			controls.value = data
		})
	}

	const loadSubscribedControls = async () => {
		return axios.get(`/options/controls/?subscribed=true`).then(({ data }) => {
			subscribedControls.value = data
		})
	}

	const loadYears = async () => {
		return axios.get(`/options/years`).then(({ data }) => {
			years.value = data.all
			currentYear.value = data.current
			Promise.resolve()
		})
	}

	const loadQuarters = async () => {
		return axios.get(`/options/quarters`).then(({ data }) => {
			quarters.value = data.all
			currentQuarter.value = data.current
			Promise.resolve()
		})
	}

	const loadFiscalYears = async () => {
		return axios.get(`/options/fiscal-years`).then(({ data }) => {
			fiscalYears.value = data.all
			currentFiscalYear.value = data.current
			Promise.resolve()
		})
	}

	const loadFiscalQuarters = async () => {
		return axios.get(`/options/fiscal-quarters`).then(({ data }) => {
			fiscalQuarters.value = data.all
			currentFiscalQuarter.value = data.current
			Promise.resolve()
		})
	}

	const loadCountries = async () => {
		return axios.get(`/options/countries`).then(({ data }) => {
			countries.value = data
		})
	}

	const loadProcesses = async () => {
		return axios.get(`/options/processes`).then(({ data }) => {
			processes.value = data
		})
	}

	const loadBusinessProcesses = async () => {
		return axios.get(`/options/business-processes`).then(({ data }) => {
			businessProcesses.value = data
		})
	}

	const loadFrequencies = async () => {
		return axios.get(`/options/frequencies`).then(({ data }) => {
			frequencies.value = data
		})
	}

	const hydrate = () => {
		//loadControls()
		//loadSubscribedControls()
		//loadYears()
		//loadQuarters()
		hydrating.value = true

		Promise.all([
			loadFiscalYears(),
			loadFiscalQuarters(),
			loadCountries(),
			loadProcesses(),
			loadBusinessProcesses(),
			loadFrequencies(),
		]).finally(() => {
			hydrating.value = false
		})
	}

	return {
		hydrating, 
		controls,
		subscribedControls,

		currentYear,
		years,

		currentFiscalYear,
		fiscalYears,

		currentQuarter,
		quarters,

		currentFiscalQuarter,
		fiscalQuarters,

		countries,
		processes,
		businessProcesses,
		frequencies,

		loadControls,
		loadSubscribedControls,
		loadYears,
		loadQuarters,
		loadFiscalYears,
		loadFiscalQuarters,
		loadCountries,
		loadProcesses,
		loadBusinessProcesses,

		hydrate,
	}
})
