import { defineStore } from 'pinia'
import {} from 'vue'

export const useLibsStore = defineStore('rysqer-libs-store', () => {
	const debounce = (fn, delay) => {
		var timer
		return function () {
			var args = arguments
			clearTimeout(timer)
			timer = setTimeout(() => {
				fn.apply(this, args)
			}, delay)
		}
	}

	return {
		debounce,
	}
})
