import { defineStore } from 'pinia'
import { ref, reactive } from 'vue'

export const useLayoutStore = defineStore('rysqer-layout-store', () => {
	let apps = reactive({})

	let active = reactive({
		app: null,
		view: null,
	})

	const createApp = (id, theViews) => {
		let views = reactive({})

		for (let i = 0; i < theViews.length; i++) {
			views[theViews[i].id] = createView(theViews[i])
		}

		apps[id] = {
			id: id,
			options: {},
			views: views,
			showTabs: true,

			activate: () => {
				active.app = apps[id]
			},
		}

		return apps[id]
	}

	const createView = (view) => {
		let theView = {
			id: view.id,
			label: view.label,
			indicator: view.indicator,
			component: view.component,
			route: view.route,
			condition: view.condition,
			options: view.options || {},
			class: view.class,
			activate: null,
			scenes: null,
			createScenes: null,
		}

		let scenes = reactive({})

		let createScenes = (theScenes) => {
			for (let i = 0; i < theScenes.length; i++) {
				scenes[theScenes[i].id] = createScene(theScenes[i], theView)
			}
		}

		let activate = () => {
			active.view = active.app.views[view.id]
		}

		theView.scenes = scenes
		theView.createScenes = createScenes
		theView.activate = activate

		return reactive(theView)
	}

	const createScene = (scene, view) => {
		let theScene = {
			id: scene.id,
			label: scene.label,
			component: scene.component,
			class: scene.class,
			options: scene.options || {},
			view: view,
			label: null,
			sections: null,
			sectionsArray: ref([]),
			createSections: null,
		}

		let sections = reactive({})
		let sectionsArray = ref([])

		let createSections = (theSections) => {
			sectionsArray.value.splice(0)

			for (let i = 0; i < theSections.length; i++) {
				const theSection = createSection(theSections[i])
				sections[theSections[i].id] = theSection
				sectionsArray.value.push(theSection)
			}
		}

		theScene.createSections = createSections
		theScene.sections = sections
		theScene.sectionsArray = sectionsArray

		return reactive(theScene)
	}

	const createSection = (section) => {
		let theSection = {
			id: section.id,
			label: section.label,
			component: section.component,
			modal: section.model || false,
			class: section.class,
			options: section.options || {},
			visible: section.visible == null ? true : section.visible,
			condition: section.condition,
			buttons: {
				tr: section.buttons?.tr, // top-right
				bl: section.buttons?.bl, // bottom-left
				br: section.buttons?.br, // bottom-right
			},

			show() {
				this.visible = true
			},

			hide() {
				this.visible = false
			},
		}

		return reactive(theSection)
	}

	return {
		apps,
		active,
		createApp,
		createView,
		createScene,
	}
})
