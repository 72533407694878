import { defineStore } from 'pinia'
import { ref, reactive, computed, watch, getCurrentInstance } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

export const useStageStore = defineStore('rysqer-stage-store', () => {
	const vm = getCurrentInstance()

	const bp = useBreakpoints(breakpointsTailwind)

	const sm = bp.smallerOrEqual('sm')
	const md = bp.between('sm', 'lg')
	const lg = bp.greaterOrEqual('lg')

	const currentBreakpoint = computed(() => {
		if (sm.value) return 'sm'
		if (md.value) return 'md'
		if (lg.value) return 'lg'
	})

	const breakpoints = computed(() => {
		return {
			sm: sm.value,
			md: md.value,
			lg: lg.value,
			current: currentBreakpoint,
		}
	})

	// watch(currentBreakpoint, () => {
	// 	console.log(currentBreakpoint.value)

	// 	if (sm.value) {
	// 		const theSizes = sizes.value.shift()
	// 		rsize(theSizes)
	// 	} else {
	// 	}
	// })

	const stages = ref([])

	const sizes = ref([])

	const createStage = (scene, position, resources, options) => {
		scene.resources = resources
		scene.options = options

		stages.value.push({
			uuid: uuidv4(),
			position: position,

			hero: {
				title: null,
				subtitle: null,
				description: null,
				class: null,
				action: {
					label: null,
					click: () => {},
					class: 'd-btn d-btn-sm d-btn-primary',
				},
			},

			resetHero: resetHero,

			scenes: [scene],
			scene: scene,
			title: null,
			options: options,

			init: init,
			get: get,

			put: put,
			unput: unput,

			tab: tab,
			untab: untab,
			retab: retab,

			resize: resize,
			rsize: rsize,

			isOn: isOn,
			isSize: isSize,

			clear: clear,
			clearFrom: clearFrom,
		})
	}

	const get = (position) => {
		if (position <= stages.value.length) return stages.value[position]
		else return null
	}

	const resetHero = (position) => {
		stages.value[position].hero = {
			title: null,
			subtitle: null,
			description: null,
			class: null,
			action: {
				label: null,
				click: () => {},
				class: 'd-btn d-btn-sm d-btn-primary',
			},
		}
	}

	const init = () => {
		stages.value.splice(0)
		sizes.value.splice(0)
	}

	const put = (scene, position, resources = {}, options = {}) => {
		if (position >= stages.value.length) {
			createStage(scene, position, resources, options)
		} else {
			stages.value[position].scene = { ...scene }
			stages.value[position].scenes = [{ ...scene }]
			stages.value[position].scene.resources = resources
			stages.value[position].scene.options = options
		}
	}

	const unput = (position, subsequent = false) => {
		if (position <= stages.value.length) {
			if (subsequent) stages.value.splice(position)
			else stages.value.splice(position, 1)

			stages.value.forEach((stage, i) => (stage.position = i))
		}
	}

	const tab = (scene, position, resources, label, options = {}) => {
		scene.label = label ?? scene.id

		if (position >= stages.value.length) {
			createStage(scene, position, resources, options)
		}

		const existingScene = stages.value[position].scenes.find((s) => s.label === label)

		if (existingScene) {
			stages.value[position].scene = existingScene
		} else {
			stages.value[position].scenes.push(scene)
			stages.value[position].scene = scene
		}

		stages.value[position].scene.resources = resources
		stages.value[position].scene.options = options
	}

	const untab = (id, position) => {
		const sceneIndex = stages.value[position].scenes.findIndex((s) => s.id === id)

		if (sceneIndex !== -1) {
			const previousSceneId = stages.value[position].scenes[sceneIndex].options?.tabs?.previous.id
			const previousSceneIndex = stages.value[position].scenes.findIndex((s) => s.id === previousSceneId)

			stages.value[position].scenes.splice(sceneIndex, 1)

			if (previousSceneIndex !== -1) {
				stages.value[position].scene = stages.value[position].scenes[previousSceneIndex]
			} else if (stages.value[position].scenes.length >= sceneIndex - 1) {
				stages.value[position].scene = stages.value[position].scenes[sceneIndex - 1]
			}
		}
	}

	const retab = (position, label) => {
		stages.value[position].scene = stages.value[position].scenes.find((s) => s.label === label)
	}

	const resize = (theSizes) => {
		vm.proxy.$nextTick(() => {
			sizes.value.splice(0, 999, ...theSizes)
		})
	}

	const rsize = (s) => {
		const base = s.reduce((accum, curr) => accum + curr, 0)
		const theSizes = s.map((ss) => {
			const relation = (ss * 100) / base
			return relation.toFixed(2)
		})

		resize(theSizes)
	}

	const isSize = (position, size) => {
		// let breakpoint

		// if (sm) breakpoint = 'sm'
		// if (md) breakpoint = 'md'
		// if (lg) breakpoint = 'lg'

		// if (position < 0 || position > sizes[breakpoint].length) return false

		// return sizes[breakpoint][position] == size

		return sizes.value[position] == size
	}

	const isOn = (scene, position = 0) => {
		if (stages.value.length > 0) return stages.value[position].scene.id == scene.id
		else return false
	}

	const clear = (position) => {
		if (position < stages.value.length) {
			stages.value[position].scenes.splice(0)
			stages.value[position].scene = null
		}
	}

	const clearFrom = (position) => {
		for (let i = position; i < stages.value.length; i++) {
			clear(i)
		}
	}

	return {
		stages,
		sizes,
		init,
		get,
		put,
		unput,
		tab,
		untab,
		retab,
		resize,
		rsize,
		isSize,
		isOn,
		clear,
		clearFrom,

		resetHero,
		breakpoints,
	}
})
