import VueRouter from 'vue-router'
import routes from './routes.js'
import { useAuthStore } from '@/stores/auth'

const router = new VueRouter({
	routes,
})

router.beforeEach((to, from, next) => {
	const needsLogin = to.matched.some((route) => {
		if (!route.meta.subject) return false
		if (useAuthStore().authenticated) return false

		const canUseRoute = useAuthStore().check('use.' + route.meta.subject)
		const canManageRoute = useAuthStore().check('manage.' + route.meta.subject)

		const canPassRoute = canUseRoute || canManageRoute

		return !canPassRoute
	})

	if (needsLogin) {
		return next({ name: 'login', query: { redirect: to.fullPath } })
	}

	return next()
})

export default router
