import { defineStore } from 'pinia'
import { ref, reactive, computed } from 'vue'
import { useOptionsStore } from '@/stores/options'
import axios from '@/libs/axios'

export const useAuthStore = defineStore(
	'rysqer-auth-store',
	() => {
		const user = ref({})
		const can = reactive({})

		const authenticated = computed(() => user.value.authenticated)

		const resetUser = () => {
			user.value = {
				uuid: null,
				name: null,
				initials: null,
				title: null,
				active: false,
				email: null,
				can: {},
				roles: [],
				tenant: null,
				settings: {},
				profile: null,

				authenticated: false,
			}
		}

		const check = (can) => {
			return !!_.get(user.value.can, can, false)
		}

		const login = async (credentials) => {
			await axios.get('/csrf-cookie')
			await axios.post('/login', credentials)

			try {
				const { data } = await axios.get('me')

				Object.assign(can, data.can)
				user.value = data
				user.value.authenticated = true

				useOptionsStore().hydrate()
			} catch (error) {
				resetUser()
			}
		}

		const logout = async () => {
			await axios.post('/logout')
			resetUser()
		}

		const forgotPassword = async (credentials) => {
			await axios.get('/csrf-cookie')
			await axios.post('/forgot-password', credentials)
		}

		const resetPassword = async (credentials) => {
			await axios.get('/csrf-cookie')
			await axios.post('/reset-password', credentials)
		}

		const updateProfile = async (profile) => {
			let currentProfile = user.value.profile || {}
			let newProfile = _.merge(currentProfile, profile)

			await axios.patch(`/users/${user.value.uuid}/profile`, newProfile)

			user.value.profile = newProfile
		}

		return {
			user,
			authenticated,
			can,
			check,

			resetUser,
			login,
			logout,
			forgotPassword,
			resetPassword,
			updateProfile,
		}
	},
	{
		persist: {
			key: 'rysqer',
		},
	}
)
