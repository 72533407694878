const routes = [
	{
		path: '*',
		name: '404',
		component: () => import('@/apps/auth/Login.vue'),
		meta: {},
	},

	/* --------------------------------------------------- */
	/* AUTH */
	/* --------------------------------------------------- */
	{
		path: '/auth/login',
		name: 'login',
		meta: {},
		component: () => import('@/apps/auth/Login.vue'),
	},

	{
		path: '/auth/not-authorized',
		name: 'not-authorized',
		meta: {},
		component: () => import('@/apps/auth/NotAuthorized.vue'),
	},

	{
		path: '/auth/forgot-password',
		name: 'forgot-password',
		meta: {},
		component: () => import('@/apps/auth/ForgotPassword.vue'),
	},

	{
		path: '/auth/reset-link-sent',
		name: 'reset-link-sent',
		meta: {},
		component: () => import('@/apps/auth/ResetLinkSent.vue'),
	},

	{
		path: '/auth/reset-password',
		name: 'reset-password',
		meta: {},
		component: () => import('@/apps/auth/ResetPassword.vue'),
	},

	/* --------------------------------------------------- */
	/* DASHBOARD */
	/* --------------------------------------------------- */
	{
		path: '/',
		name: 'home',
		component: () => import('@/apps/dashboards/Home.vue'),
		props: { view: 'dashboard' },
		meta: {
			subject: 'apps.ics.app',
		},
	},

	/* --------------------------------------------------- */
	/* ICS */
	/* --------------------------------------------------- */
	{
		path: '/ics',
		name: 'ics-default',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			subject: 'apps.ics.app',
		},
	},

	{
		path: '/ics/tasks',
		name: 'ics-tasks',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			subject: 'apps.ics.app',
		},
	},

	{
		path: '/ics/tasks/:uuid',
		name: 'ics-tasks-resource',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			subject: 'apps.ics.app',
		},
	},

	{
		path: '/ics/processes',
		name: 'ics-processes',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'processes' },
		meta: {
			subject: 'apps.ics.app',
		},
	},

	{
		path: '/ics/coordinator',
		name: 'ics-coordinator',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator' },
		meta: {
			subject: 'apps.ics.app',
		},
	},

	{
		path: '/ics/coordinator/rcm',
		name: 'ics-coordinator-rcm',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator', scene: 'rcm' },
		meta: {
			subject: 'apps.ics.app',
		},
	},

	{
		path: '/ics/tester',
		name: 'ics-tester',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tester' },
		meta: {
			subject: 'apps.ics.app',
		},
	},

	/* --------------------------------------------------- */
	/* RMS */
	/* --------------------------------------------------- 

	{
		path: '/rms',
		name: 'rms-home',
		component: () => import('@/apps/rms/Home.vue'),
		meta: {
			subject: 'apps.rms.app',
		},
	},

	{
		path: '/rms/risks',
		name: 'rms-risks',
		component: () => import('@/apps/rms/views/Risks/Home.vue'),
		meta: {
			subject: 'apps.rms.app',
		},
	},

	{
		path: '/rms/tasks',
		name: 'rms-tasks',
		component: () => import('@/apps/rms/views/Tasks/Home.vue'),
		meta: {
			subject: 'apps.rms.app',
		},
	},

	{
		path: '/rms/tasks/risks/:riskUuid/comments/:commentUuid',
		name: 'rms-tasks-comments',
		component: () => import('@/apps/rms/views/Tasks/Home.vue'),
		meta: {
			subject: 'apps.rms.app',
		},
	},

	{
		path: '/rms/registers',
		name: 'rms-registers',
		component: () => import('@/apps/rms/views/Risks/Home.vue'),
		meta: {
			subject: 'apps.rms.app',
		},
	},

	{
		path: '/rms/heatmap',
		name: 'rms-heatmap',
		component: () => import('@/apps/rms/views/Heatmap/Home.vue'),
		meta: {
			subject: 'apps.rms.app',
		},
	},
	*/
]

export default routes
