import Vue from 'vue'
import VueTailwind from 'vue-tailwind'
import { } from 'vue-tailwind/dist/components'

import tCheckboxGroup from './src/libs/tailwindVue/tCheckboxGroup'
import tCheckboxButtons from './src/libs/tailwindVue/tCheckboxButtons'

import tRadioGroup from './src/libs/tailwindVue/tRadioGroup'
import tRadioButtons from './src/libs/tailwindVue/tRadioButtons'
import tRadioCardsStacked from './src/libs/tailwindVue/tRadioCardsStacked'

import tLink from './src/libs/tailwindVue/tLink'
import tInput from './src/libs/tailwindVue/tInput'

import tRichSelect from './src/libs/tailwindVue/tRichSelect'
import tTable from './src/libs/tailwindVue/tTable'
import tDialog from './src/libs/tailwindVue/tDialog'
import tModal from './src/libs/tailwindVue/tModal'
import tModalDetail from './src/libs/tailwindVue/tModalDetail'

const settings = {
  't-input': tInput,
  't-link': tLink,

  't-checkbox-group': tCheckboxGroup,
  't-checkbox-buttons': tCheckboxButtons,

  't-radio-group': tRadioGroup,
  't-radio-buttons': tRadioButtons,
  't-radio-cards-stacked': tRadioCardsStacked,
  
  't-rich-select': tRichSelect,
  't-table': tTable,
 
  't-dialog': tDialog,
  't-modal': tModal,
  't-modal-detail': tModalDetail,
}

Vue.use(VueTailwind, settings)
